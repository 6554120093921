import React from "react";
import { makeStyles, Typography, Paper, Grid } from "@material-ui/core";
import ScrollToTop from "../components/scrollToTop";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    overflow: "wrap",
  },
  header: {
    marginLeft: "45px",
  },
  container: {
    margin: "45px",
    padding: "45px",
  },
  row: {
    backgroundColor: "#343434",
  },
  alt: {
    backgroundColor: theme.palette.background.default,
  },
}));

interface PriceProps {
  text: string;
  duration: string;
  price: string;
  clazz?: string;
}

const Price: React.FC<PriceProps> = ({ text, duration, price, clazz }) => (
  <Grid container wrap="nowrap" spacing={2} className={clazz}>
    <Grid item xs={8} zeroMinWidth>
      <Typography>{text}</Typography>
    </Grid>
    <Grid item xs={2}>
      <Typography>{duration}</Typography>
    </Grid>
    <Grid item xs={2}>
      <Typography>£{price}</Typography>
    </Grid>
  </Grid>
);

const priceList: PriceProps[] = [
  { text: "Initial consultation", duration: "1 hr", price: "85" },
  { text: "Follow up treatment sessions", duration: "55 mins", price: "75" },
  { text: "Online triage", duration: "20 mins", price: "37.50" },
  { text: "Initial online consultation", duration: "50 mins", price: "67.50" },
  {
    text: "Follow up online consultation",
    duration: "30 mins",
    price: "50",
  },
  { text: "Sports Massage", duration: "55 mins", price: "75" },
  { text: "Sports Massage", duration: "30 mins", price: "52.50" },
  { text: "Stretching Session", duration: "30 mins", price: "52.50" },
  {
    text: "Performance enhancement programme consultation & programme design (with photos)",
    duration: "1 hr",
    price: "152.50",
  },
  {
    text: "Gait Analysis (assessment, plan and review)",
    duration: "2 hr",
    price: "197.50",
  },
  { text: "Gait Coaching", duration: "55 mins", price: "75" },
  {
    text: "Home visit within 5 miles of the clinic (over 5 miles P.O.A.)",
    duration: "",
    price: "37.50",
  },
];

function isEven(value: number) {
  return value % 2 === 0;
}

const Prices = () => {
  const classes = useStyles();

  const prices = priceList.map((item, i) => (
    <Price {...item} clazz={isEven(i) ? classes.row : classes.alt} />
  ));

  return (
    <div>
      <ScrollToTop />
      <div className={classes.root}>
        <Typography variant="h3" className={classes.header}>
          Prices
        </Typography>
        <Paper className={classes.container}>{prices}</Paper>
        <Paper className={classes.container}>
          <br />
          <Typography>Team and group discounts available on request</Typography>
          <br />
          <Typography>
            Positive Movement accepts the following forms of payment: cash, bank
            transfer &amp; cheque.
          </Typography>
          <br />
          <Typography variant="h5">COVID-19</Typography>
          <Typography>
            Prices now include costs associated with safety measures put in
            place in response to the COVID-19 pandemic. Including additional
            cleaning supplies, increased time between patients (so to minimise
            patient to patient contact) and PPE supplies.
          </Typography>
          <br />
          <Typography variant="h5">Cancellation policy</Typography>
          <Typography>
            Positive Movement operates a 24 hour cancellation policy. If you
            need to rearrange or cancel an appointment we ask for a minimum of
            24 hours notice otherwise a cancellation charge of the full
            appointment fee will be applied.
          </Typography>
        </Paper>
      </div>
    </div>
  );
};

export default Prices;
